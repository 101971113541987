
import pwaControl from '~/mixins/pwaControl'
import notifications from '~/mixins/notifications'

export default {
  mixins: [pwaControl, notifications],
  mounted () {
    if (!this.getCookie('first_page_visited')) {
      this.setCookie('first_page_visited', this.$route.path)
    }

    if ('utm_source' in this.$route.query && !this.getCookie('first_utm_source')) {
      this.setCookie('first_utm_source', this.$route.query.utm_source)
    }
  }
}
